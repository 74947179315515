<template>
  <div class="login" >
    <div class="container">

      <b-modal size="lg" v-model="isRegisterChoiceModalShown" hide-footer>
        <template v-slot:modal-title>
          <strong> Créer votre compte</strong>
        </template>

        <p>
          Créez votre compte et commencez directement à utiliser l'application
        </p>
        <div class="row mt-3">
          <div class="col-12 col-lg-6">
            <button
              class="gavc-btn account-creation-btn"
              @click="$router.push('/inscription/accueil/cedant')"
            >
              Je souhaite céder mon entreprise
            </button>
          </div>
          <div class="col-12 col-lg-6 mt-3 mt-lg-0">
            <button
              class="gavc-btn account-creation-btn"
              @click="$router.push('/inscription/accueil/repreneur')"
            >
              Je souhaite reprendre une entreprise
            </button>
          </div>
        </div>
      </b-modal>


      <div class="row">
        <div class="col-12 text-center">
          <h1 class="gavc-h1 text-center">Content de vous revoir 🙂</h1>
          <p class="gavc-chapo mt-4">
            Accédez à votre espace
          </p>
          <p class="gavc-p">
            Votre espace sécurisé <strong>Garance à vos côtés</strong> vous permet de gérer et de suivre votre projet de cession ou de reprise.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <b-form v-on:submit.prevent="login()">
            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': username && $v.username.$dirty ? $v.username.$error : null  }">
                <label class="gavc-label" for="input-username">
                    Votre email
                    <span class="gavc-required"> * </span>
                </label>
                <input
                  autocomplete="on"
                  class="gavc-input"
                  id="input-username"
                  placeholder="Email"
                  required
                  type="text"
                  v-model="username">
            </div>

            <div
              class="gavc-field"
              v-bind:class="{ 'is-error': password && $v.password.$dirty ? $v.password.$error : null  }">
                <label class="gavc-label" for="input-password">
                    Votre mot de passe
                    <span class="gavc-required"> * </span>
                </label>
                <passwordInput
                  id="input-password"
                  maxLength="100"
                  @value-changed="password = $event"
                  @input="$v.password.$touch()"
                  :defaultValue="password"
                  placeholder="Mot de passe"
                />
            </div>
            <p class="mt-2 gavc-legende text-danger"
                 v-if="errorLogin">
                 Veuillez vérifier votre email et mot de passe
            </p>
            <p class="mt-3 gavc-legende"> <router-link to="/mot-de-passe-oublie"> Mot de passe oublié ?</router-link> </p>

            <div class="text-center">
              <button
                class="gavc-btn"
                :disabled="contactInputInvalids || isLoginInProcess"
                type="submit"
              >
                Se connecter
              </button>
            </div>
          </b-form>

          <p
            class="mt-4 gavc-legende"
          >
            Pas encore de compte ?
            <a
              href="#"
              @click="isRegisterChoiceModalShown=true"
            >
              Inscrivez-vous gratuitement
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

const passwordInput = () => import('@/components/inputs/passwordInput')

export default {
  name: 'Login',
  components: {
    passwordInput
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      errorLogin: false,
      isLoginInProcess: false,
      isRegisterChoiceModalShown: false,
      password: '',
      username: '',
    }
  },
  computed: {
    contactInputInvalids: function () {
      this.$v.$touch()
      return this.$v.$invalid
    }
  },
  validations: {
    username: {
      required,
      email
    },
    password: {
      required,
    }
  },

  methods: {
    login: function () {
      this.isLoginInProcess = true
      this.$store.commit('loadFromAPI')
      var username = this.username.toLowerCase().trim()
      var password = this.password
      this.errorLogin = false
      this.$store.dispatch('AUTH_REQUEST', { username, password })
      .then((result) => {
        result.data.groups.forEach((item) => {
          if (item.name === "CEDANT") {
            this.$router.push('/cedant/tableau-de-bord')
          }
          if (item.name === "REPRENEUR") {
            this.$router.push('/repreneur/tableau-de-bord')
          }
        })
      })
      .catch(() => {
        this.errorLogin = true
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isLoginInProcess = false
      })
   },
  }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';

.account-creation-btn {
  width: 100%;
}
</style>
